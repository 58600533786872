import React, { useEffect } from 'react';
import CommonSprite from '@/assets/images/sprites/common.svg';
import Link from '@/components/atoms/link';
import t from '@/lib/i18n';
import usePageView from '@/lib/hooks/usePageView';
import classnames from 'classnames';
import './style.scss';

export type SecurityLayoutProps = {
  children: JSX.Element;
  goBack?: {
    label: string;
    url: string;
  };
  className?: string;
  pageView?: string;
};

const SecurityLayout = ({
  children,
  goBack,
  className,
  pageView
}: SecurityLayoutProps) => {
  const pv = usePageView();

  useEffect(() => {
    if (pageView) {
      pv.send(pageView);
    }
  }, [pv, pageView]);

  return (
    <div className={classnames('securityLayout', className)}>
      <div className="securityLayout__container">
        {goBack && (
          <div className="securityLayout__header">
            <Link
              href={goBack.url}
              className="securityLayout__goBack"
              label={goBack.label}
            />
          </div>
        )}
        <div className="securityLayout__content">
          <div className="securityLayout__logo">
            <svg width="152" height="29">
              <use xlinkHref={`${CommonSprite}#logo`} />
            </svg>
          </div>
          {children}
        </div>
        <div className="securityLayout__footer">
          <Link
            href="/help-and-contact/vas"
            label={t('common.help')}
            className="securityLayout__link"
          />
          {' - '}
          <Link
            href="/legal-informations/personal-data-and-cookies"
            label={t('cookies.privateData')}
            className="securityLayout__link"
          />
        </div>
      </div>
    </div>
  );
};

export default SecurityLayout;

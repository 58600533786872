import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import { NewUserLocal } from '@/store/register/types';
import Date from '@/lib/utils/date';
import { notification } from '@/lib/notification';
import t from '@/lib/i18n';
import { DisabledAccountException } from '../security/exceptions';

type ResType = {
  status: undefined | string;
  fidError: any;
  data: any;
};
class AccountApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi']);
  }

  async createAccount(data: NewUserLocal): Promise<ResType> {
    let res: ResType = {
      status: 'error',
      fidError: null,
      data: []
    };
    const now = Date().format('DD/MM/YYYY');
    let body;

    body = {
      adresse1: data.city,
      adresse2: data.additionalInformation,
      adresse3: data.address,
      adresse4: data.additionalAddress,
      civilite: data.civility,
      codePostal: data.zipCode,
      dateAdrOrda: now,
      dateEmailOrda: now,
      email: data.email,
      mdp: data.password,
      mob: data.mobilePhone,
      nom: data.lastName,
      nif: data.nif,
      optins: [
        {
          nom: 'emailITM',
          valeur: Boolean(data.itmEmail)
        },
        {
          nom: 'smsITM',
          valeur: Boolean(data.mobilePhone) && Boolean(data.itmSms)
        },
        {
          nom: 'emailGrptPrn',
          valeur: Boolean(data.partnerSms)
        },
        {
          nom: 'smsGrptPrn',
          valeur: Boolean(data.mobilePhone) && Boolean(data.partnerSms)
        }
      ],
      optouts: [
        {
          nom: 'solCom',
          valeur: data.personalCommunication
        },
        { nom: 'navWeb', valeur: true },
        { nom: 'crmDig', valeur: true }
      ],
      pays: data.country,
      pdv: data.pdv,
      prenom: data.firstName,
      statutAdrOrda: '3',
      tel: data.phone,
      ville: data.city
    };

    if (data.cguValided) {
      body = {
        ...body,
        pdvFid: data.cardInfos?.pdv ?? data.pdv,
        veutCarteDemat: true,
        cgus: [
          {
            codeCgu: t('common.cguFid.code'),
            dateAcceptation: now,
            idVerCgu: t('common.cguFid.version')
          }
        ]
      };
    }

    if (data.hasFidelityCard) {
      body = {
        ...body,
        numfid: data.fidelityCardNumber,
        activateCard: data.cguValided
          ? data.cardInfos?.status === 'ENC'
          : false,
        attachCard: data.cguValided,
        veutCarteDemat: false
      };
    }

    if (!data.isPro) {
      body = {
        ...body,
        dateN: Date(data.birthdayDate).format('DD/MM/YYYY')
      };
    }

    if (data.isPro) {
      body = {
        ...body,
        veutCarteDemat: false,
        personneMorale: {
          raisonSociale: data.company,
          type: 'SC'
        }
      };
    }

    try {
      const response = await this.axios.post(
        `/gestiondecompte/v1/moncompte`,
        body
      );
      if (response.status === 200) {
        res = {
          status: 'success',
          data: response.data,
          fidError: null
        };
      }
    } catch (error: any) {
      logger.error({
        message: 'Unable to register user',
        error
      });

      res = {
        status: 'error',
        fidError:
          error?.response?.data?.errors?.[0]?.code ||
          error?.response?.data.err?.[0]?.code,
        data:
          error?.response?.data?.err ||
          error?.response?.data?.errors ||
          error?.errors
      };
    }
    return res;
  }

  async sendResetPasswordEmail(email: string): Promise<boolean> {
    let resultApi = false;

    try {
      const response = await this.axios.post(
        `/gestiondecompte/v1/moncompte/${encodeURIComponent(email)}/renewPwd`
      );
      if (response.status === 204) {
        resultApi = true;
      }
    } catch (error: any) {
      if (
        error.response?.status === 400 &&
        error.response.data.errors[0].code === 'USER_NOT_ACTIVATED'
      ) {
        throw new DisabledAccountException();
      } else {
        logger.error({
          message: 'Unable to send reset password email',
          error
        });
      }
    }

    return resultApi;
  }

  async acountActivation(UID: string): Promise<any | null> {
    let response;

    try {
      response = await this.axios.get(
        `/gestiondecompte/v1/moncompte/activation?jeton=${UID}`
      );
    } catch (error: any) {
      if (error.response.status === 400) {
        return false;
      }
      logger.error({
        message: 'Unable to activate account',
        error
      });
      throw error;
    }

    return !!response?.data?.id;
  }

  async resendActivationEmail(email: string) {
    let response = null;

    try {
      response = await this.axios.get(
        `/gestiondecompte/v1/moncompte/renouvelerActivation`,
        {
          params: {
            email
          }
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to resend activation email',
        error
      });
    }

    return response;
  }

  async resendActivationEmailV2(email: string) {
    let response = null;

    try {
      response = await this.axios.post(
        `/registration/v2/users/renew_activation`,
        {
          email
        }
      );
    } catch (error: any) {
      notification.error(t('notification.erreur'));
      logger.error({
        message: 'Unable to resend activation email',
        error
      });
    }

    return response;
  }

  async newEmailConfirmation(token: string): Promise<boolean> {
    let resultApi = false;

    try {
      const response = await this.axios.get(
        `/gestiondecompte/v1/moncompte/activation?jeton=${token}`
      );
      if (response.status === 200) {
        resultApi = true;
      }
    } catch (error: any) {
      logger.error({
        message: 'Unable to send new email confirmation',
        error
      });
    }

    return resultApi;
  }

  async emailAlreadyExist(email: string) {
    let resultApi = false;

    try {
      const response = await this.axios.post(
        '/gestiondecompte/v1/moncompte/validation',
        {
          email
        }
      );

      if (response.status === 204) {
        resultApi = true;
      }
    } catch (error: any) {
      logger.error({
        message: 'Unable to check if email already exists',
        error
      });
    }

    return resultApi;
  }

  async emailAlreadyExistV2(email: string) {
    let resultApi: { result: boolean; errorKey?: string } = { result: false };

    try {
      const response = await this.axios.post(
        '/gestiondecompte/v2/moncompte/validation',
        {
          email
        }
      );

      if (response.status === 204) {
        resultApi = { result: true };
      }
    } catch (error: any) {
      resultApi = {
        result: false,
        errorKey: error?.response?.data?.errors?.[0]?.code
      };

      logger.error({
        message: 'Unable to check if email already exists',
        error
      });
    }

    return resultApi;
  }

  async renewPassword(password: string, token: string) {
    let resultApi = false;
    try {
      const response = await this.axios.put(`/gestiondecompte/v1/passwords`, {
        password,
        token
      });

      if (response.status === 200) {
        resultApi = true;
      }
    } catch (error: any) {
      logger.error({
        message: 'Unable to renew password',
        error
      });
    }
    return resultApi;
  }
}

export default new AccountApi();
